.base-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.container-right {
  flex-grow: 1;
  overflow-y: hidden;
}

.container-dynamic {
  height: calc(100vh - 73px);
  width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(var(--black-rgb), 0.2) transparent !important;
}

.sidebar {
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}

.logo-menu {
  height: 15px;
}

.submenu {
  width: 280px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}

.submenu-icon {
  width: 20px;
  height: 20px;
}

.layout-top-bar {
  height: 50px;
}

.menu-father-div {
  width: 100%;
  text-decoration: none;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.menu-child {
  cursor: pointer;
  display: block;
  font-weight: 400;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  position: relative;
  text-decoration: none;
  transition: background 0.5s ease-in-out;
  width: 100%;

  .nav-link {
    color: rgba(233, 236, 239, 0.7) !important;

    &:hover {
      color: rgba(233, 236, 239, 1) !important;
      // border-left: 3px solid transparent;
      // border-left-color: #3b7ddd;
    }
  }

  &.selected {
    .nav-link {
      color: rgba(233, 236, 239, 1) !important;
      font-weight: bolder;
      // border-left: 3px solid transparent;
      // border-left-color: #3b7ddd;
    }
  }
}

.menu-submenu,
.menu-father {
  // border-left: 3px solid transparent;
  // border-left-color: transparent;
  cursor: pointer;
  display: block;
  font-weight: 400;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 15px;
  position: relative;
  text-decoration: none;
  transition: background 0.5s ease-in-out;

  .nav-link {
    color: rgba(233, 236, 239, 0.7) !important;

    &:hover {
      color: rgba(233, 236, 239, 1) !important;
    }
  }

  &:hover {
    font-weight: bolder;
    border-left-color: #3b7ddd;
  }

  &.selected {
    background: linear-gradient(
      90deg,
      rgba(59, 125, 221, 0.1),
      rgba(59, 125, 221, 0.088) 50%,
      transparent
    );
    border-left-color: #3b7ddd;

    .nav-link {
      color: rgba(233, 236, 239, 1) !important;
      font-weight: bolder;
    }
  }
}

.bg-dark {
  .accordion-item {
    border: none;
    background: linear-gradient(
      90deg,
      rgba(59, 125, 221, 0.1),
      rgba(59, 125, 221, 0.088) 50%,
      transparent
    );

    &:hover {
      // border-left: 3px solid transparent;
      // border-left-color: #3b7ddd !important;

      font-weight: bolder !important;
      color: #fff;
    }
  }
  .accordion-button {
    &:after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23dfdfdf'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
  }
}

.submenu-accordion {
  padding: 0.5rem 1rem !important;
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.088) 50%,
    transparent
  );
  font-weight: 400 !important;

  &.collapsed {
    color: rgba(233, 236, 239, 0.702) !important;
    background: $dark !important;
    font-weight: 400 !important;
    border-left-color: transparent !important;
  }

  &:hover {
    color: #fff !important;
    border-left-color: #3b7ddd !important;
  }
}

.collapse-menu {
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.088) 50%,
    transparent
  );
}

.card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;

  .card-body {
    height: 100% !important;
    overflow: auto;
  }
}

.card-item {
  margin-bottom: 8px;
  margin-top: 16px;
  opacity: 1;
  background: rgb(248, 249, 250) none repeat scroll 0% 0%;
  color: rgb(52, 71, 103);
  border-radius: 0.75rem;
}

// Pulse notification
.pulse {
  height: 40px;
  width: 40px;
  top: -12px;
  left: 20px !important;
  border: 5px solid #ef5350;
  border-radius: 70px;
  animation: pulse 1s ease-out infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

// End Pulse Notification

// Notification
.notification-error {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
