// padding: 1rem;
/* These styles are suggested for the table fill all available space in its containing element */
//   display: block;
/* These styles are required for a horizontaly scrollable table overflow */
//   overflow: auto;

.table {
  border-spacing: 0;

  .thead {
    /* These styles are required for a scrollable body to align with the header properly */
    overflow-y: auto;
    overflow-x: hidden;
  }

  .tbody {
    /* These styles are required for a scrollable table body */
    overflow-y: scroll;
    overflow-x: hidden;
    height: 250px;
  }

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
    border-bottom: 1px solid black;
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    border-right: 1px solid black;

    /* In this example we use an absolutely position resizer,
       so this is required. */
    position: relative;

    :last-child {
      border-right: 0;
    }

    .resizer {
      right: 0;
      background: blue;
      width: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      /* prevents from scrolling while dragging on touch devices */
      touch-action: none;

      &.isResizing {
        background: red;
      }
    }
  }
}
