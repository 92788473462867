// Color Palette
$body-bg: #fafafa !default;
$body-color: #3f4254 !default;

// $primary: #118ab2;
// $secondary: #227c9d;
// $info: #004e92;
$success: #06d6a0;
$warning: #ffcb77;
$danger: #ef476f;
// $light: #fef9ef;
$dark: #2b2d42;

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #ef233c;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #fff;
$black: #000;
$gray: #ddd;

$ydental-dark: linear-gradient(-154deg, #004e92 0, #000428 100%) !default;
$ydental-gradient: linear-gradient(
  65deg,
  rgba(40, 68, 128, 1) 0%,
  rgba(41, 87, 164, 1) 5%,
  rgba(21, 165, 179, 1) 15%,
  rgba(21, 165, 179, 0.8) 20%,
  rgba(21, 165, 179, 0.5) 25%,
  #fff 35%,
  rgba(255, 255, 255, 0) 100%
) !default;
$ydental-light: #7dccff !default;
$ydental-blue: #1c208c !default;
$ydental-dark-blue: #115571 !default;
$ydental-light-blue: #5667ff !default;
$ydental-gray: rgb(180, 185, 189) !default;

// #2B0548
// #4630ab
// #6669c5
// #1190cb
// #7dccff
// #141414
// #31afb4
// #115571
// #14212d

// Font Configurations
$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-weight-base: 400;
$root-font-size: 1rem;
