// Global component styles

html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -ms-text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  // font-size: $root-font-size !important;
  // font-family: $font-family-sans-serif;
  // font-weight: $font-weight-base;
  margin: 0px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  background-color: #f5f6ff !important;
  // background-color: #f8f9fa;

  -ms-text-size-adjust: 100%; // 2
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.h1 {
  color: rgb(52, 71, 103);
}
.h2 {
  color: rgb(103, 116, 142);
}
.h5 {
  margin: 0px 0px 0.35em;
  font-size: 1.25rem;
  line-height: 1.375;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 700;
}

.h6 {
  margin: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.01071em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(103, 116, 142);
  font-weight: 500;
}

#react-select-5-listbox {
  z-index: 9999999 !important;
}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.content-main {
  width: 100%;
  min-height: 100%;
  flex-direction: column !important;
  background-color: #fff;
}

.bg-ydental-gradient {
  background: rgb(40, 68, 128);
  background: $ydental-gradient;
}

.bg-ydental-blue {
  background-color: $ydental-blue;
}

.txt-ydental-blue {
  color: $ydental-blue;
}

.bg-ydental-light-blue {
  background-color: $ydental-light-blue;
}

.txt-ydental-light-blue {
  color: $ydental-light-blue;
}

.bg-ydental-dark-blue {
  background-color: $ydental-dark-blue;
}

.txt-ydental-dark-blue {
  color: $ydental-dark-blue;
}

.bg-ydental-light {
  background: $ydental-light;
}

.txt-ydental-light {
  color: $ydental-light;
}

.bg-ydental-dark {
  background: $ydental-dark;
}

.txt-ydental-dark {
  color: $ydental-dark;
}

.bg-ydental-gray {
  background-color: $ydental-gray;
}

.txt-ydental-gray {
  color: $ydental-gray;
}

.bg-blue {
  background-color: $blue;
  color: $white;
}

.txt-blue {
  color: $blue;
}

.bg-indigo {
  background-color: $indigo;
  color: $white;
}

.txt-indigo {
  color: $indigo;
}

.bg-purple {
  background-color: $purple;
  color: $white;
}

.txt-purple {
  color: $purple;
}

.bg-pink {
  background-color: $pink;
  color: $black;
}

.txt-pink {
  color: $pink;
}

.bg-red {
  background-color: $red;
  color: $black;
}

.txt-red {
  color: $red;
}

.bg-orange {
  background-color: $orange;
  color: $black;
}

.txt-orange {
  color: $orange;
}

.bg-yellow {
  background-color: $yellow;
  color: $black;
}

.txt-yellow {
  color: $yellow;
}

.bg-green {
  background-color: $green;
  color: $white;
}

.txt-green {
  color: $green;
}

.bg-teal {
  background-color: $teal;
  color: $white;
}

.txt-teal {
  color: $teal;
}

.bg-cyan {
  background-color: $cyan;
  color: $black;
}

.txt-cyan {
  color: $cyan;
}

.bg-white {
  background-color: $white;
  color: $black;
}

.txt-white {
  color: $white;
}

.bg-black {
  background-color: $black;
  color: $white;
}

.txt-black {
  color: $black;
}

.card {
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  border: 0;
}

.card-header {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: transparent;
  border-bottom: 1px solid #ebedf3;
  align-items: stretch;
}

.shadow-box {
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}

.react-datepicker__input-container input {
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #e9ebf4;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
}

.active-tab {
  background: linear-gradient(-180deg, #004e92 0, #000428 100%) !important;
  box-shadow: 2px 2px 2px;
}

.table-top-bar {
  background: linear-gradient(-180deg, #000428 0, #004e92 100%) !important;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  // border: 1px solid darkgrey;
  // box-shadow: 5px 5px 5px 1px #ccc;
  // border-radius: 5px;
  width: 100% !important;
  height: 100% !important;
}

// FINAL PDF STYLES
.rdl-control-container {
  border: 2px solid #dedede;
}
.rdl-control {
  background-color: #f8f9fa;
}
